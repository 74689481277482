@charset "utf-8";

// Define defaults for each variable.

$sans: 'Source Sans Pro', Arial, sans-serif; // 400, 600, 900
$serif: 'Merriweather', georgia, serif; // 700, 900

$sans-fw-regular: 400;
$sans-fw-bold: 600;
$sans-fw-bolder: 900;
$serif-fw-bold: 700;

$base-font-family: $sans;
$base-font-size:   1rem;
$base-font-weight: $sans-fw-regular;

$heading-font-family: $serif;
$strong-font-weight: $sans-fw-bold;
$strongest-font-weight: $sans-fw-bolder;

$base-line-height: 1.5;

$spacing-unit: 1em;
$margin-unit: 1rem;

$transition-timing: 0.32s;

// https://coolors.co/19323c-f3f7f0-c490d1-907ad6-dd403a
// https://coolors.co/122c34-130802-eac435-da3e52-7f95d1

/////
// CSS VARS
/////
/// 
//

:root {
  // base colors
  --color-neutral-white: hsl(0, 0%, 100%);
  --color-neutral-gray-pink: hsl(315, 31%, 95%);
  --color-neutral-gray-pink-2: hsl(0, 0%, 51%);
  --color-neutral-black: hsl(0, 0%, 0%);

  // theme colors
  --color-primary:hsl(285, 35%, 22%);
  --color-secondary: hsl(285, 46%, 42%);
  --color-tertiary: hsl(25, 97%, 41%);

  --background-color: hsl(5, 60%, 96%);
  --background-color-layer2:hsl(0, 0%, 100%);
  --background-color-layer3:hsl(5, 60%, 80%);
  --background-color-hover:hsl(0, 68%, 91%);
  
  --background-gradient-1:hsl(0, 78%, 89%);
  --background-gradient-2:hsl(0, 68%, 91%);

  --text-color: hsl(21, 81%, 4%);
  
  --link-color: hsl(2, 65%, 44%);
  --link-hover: hsl(2, 74%, 34%);
  --link-visited: hsl(2, 92%, 30%);
  --button-link-color: hsl(311, 100%, 98%);

  @media (prefers-color-scheme: dark) {
    --color-primary:hsl(285, 81%, 68%);
    --color-secondary: hsl(82, 74%, 73%);
    --color-tertiary: hsl(25, 75%, 61%);
    
    --background-color: hsl(252, 43%, 20%);
    --background-color-layer2:hsl(252, 43%, 10%);
    --background-color-layer3:hsl(252, 43%, 70%);
    --background-color-hover:hsl(252, 42%, 48%);

    --background-gradient-1:hsl(276, 62%, 25%);
    --background-gradient-2:hsl(286, 84%, 12%);

    --text-color: hsl(293, 31%, 95%);
    
    --link-color: hsl(50, 98%, 49%);
    --link-hover: hsl(50, 98%, 79%);
    --link-visited: hsl(50, 98%, 89%);
    --button-link-color: hsl(21, 81%, 4%);
  } 
}

$border-width: 0.35rem;

$table-text-align: left;

// Width of the content area
$content-width-small:    550px;
$content-width:    940px;
$content-width-large:   1200px;
$hero-width-medium: 1100px;
$hero-width:   1300px;

// breakpoints
$phone:          400px;
$tablet:          600px;
$laptop:        940px;
$desktop:        1200px;
$wide-desktop:    1800px;

// mixins for media-queries
@mixin media-query-min($device) {
  @media screen and (min-width: $device) {
    @content;
  }
}

@mixin media-query-max($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

@mixin relative-font-size($ratio) {
  font-size: $base-font-size * $ratio;
}

@mixin fs($size:16px) {
  font-size: calc( $size / 16) + rem;
}

// Import partials.
@import
  "minima/fonts",
  "minima/base",
  "minima/links",
  "minima/layout"
;
