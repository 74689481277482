/**
 * Site header
 */
.site-header {
  padding-top: calc($spacing-unit * 2);
  padding-bottom: $spacing-unit;

  // Positioning context for the mobile navigation icon
  position: relative;

  // blurry circle
  &::before {
    content: " ";
    // background-color: var(--color-primary);
    background: radial-gradient(circle, var(--color-primary) 0%, var(--background-color) 50%, var(--background-color) 100%); 
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 100%;
    filter: blur(5rem);
    transform: translate3d(0, 0, 0);
    opacity: 0.25;
    z-index: 0;

    @include media-query-min(800px) {
      top: -7rem;
      left: calc(50% - 30rem);
      max-width: 60rem;
      height: 60rem;
    }  
  }
}

.site-header .wrapper {
  display: flex;
  flex-flow: column nowrap;

  @include media-query-min(800px) {
    display: grid;
    grid-template-columns: min-content auto;
    grid-template-rows: auto auto;
    gap: $margin-unit;
  }
}


/**
 * Site footer
 */
.site-footer {
  padding: calc($spacing-unit * 2) 0;
  position: relative;
  border-top: $border-width solid var(--link-color);

  p {
    @include fs(16);
    margin-bottom: $margin-unit;

    @include media-query-min($laptop) {
      @include fs(20);
    }
  }
}

.footer-heading {
  @include fs(22);
  margin-bottom: calc($margin-unit / 2);
}

.contact-list,
.social-media-list {
  list-style: none;
  margin-left: 0;
  margin: 0;
}

.social-media-list li a {
  @media (prefers-reduced-motion: no-preference) {
    &:hover {
      animation: pulse 0.5s ease-out;
    }
  }
}

.footer-item__wrapper {
  @include fs(22);
  color: var(--text-color);
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: space-between;
}

.site--description {
  flex: 0 100%;
}

.contact-list {
  flex:1 100%;

  @include media-query-min($tablet) {
    flex: 1 40%;
  }

  li {
    @include fs(16);

    @include media-query-min($laptop) {
      @include fs(18);
    }
  }
}

/**
 * Page content
 */
.page-content {
  flex: 1;
  overflow: hidden;
}

.alert {
  background-color: var(--color-tertiary);
  border: calc($border-width / 2) solid var(--color-tertiary);
  padding: $spacing-unit;
  font-weight: bold;
}

// archive grid
.post-list {
  margin-left: 0;
  margin-bottom: 0;
  list-style: none;
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: $spacing-unit;
  grid-row-gap: calc($spacing-unit * 2);

  @include media-query-min($tablet) {
    grid-template-columns: 1fr 1fr;
    grid-row-gap: calc($spacing-unit * 2);
    grid-column-gap: calc($spacing-unit * 2);
  }

  @include media-query-min($wide-desktop) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  li {
    display: flex;
    justify-content: flex-start;
    flex-flow: column nowrap;
    align-items: stretch;
  }

  @media (prefers-reduced-motion: no-preference) and (min-width: $tablet) {
    li[data-component-animation="true"] {
      visibility: hidden; // start with the element being invisible
    }

    li[data-component-animation="true"][component-is-animating="true"] {
      animation-name: slideInUp; // name of the animation to use
      animation-duration: 0.85s; // duration of the animation
      animation-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1); // easing function for the animation
      animation-fill-mode: forwards; // ensures the animation stays in its final state
      visibility: visible; // make the element visible once the animation starts
    }

    a.post-link {
      &:hover {
        animation: pulse 0.5s ease-out;
      }
    }
  }
}

.project-list {
  li:last-child {
    @include media-query-max($laptop) {
      margin-bottom: calc($spacing-unit * 2);
    }
  }

}

.post-meta {
  @include fs(18);
  color: var(--text-color);
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

/**
 * post teaser
 */
.post-teaser-content {
  display: flex;
  flex-flow: column nowrap;
  padding: 0 $spacing-unit;
}

.category-name {
  @include fs(12);
  flex: 0 auto;
  align-self: flex-start;
  font-weight: $strong-font-weight;
  text-transform: uppercase;
  letter-spacing: calc($spacing-unit / 12);
  color: inherit;
  
  @include media-query-min($tablet) {
    @include fs(14);
  }

  .post-header &,
  .project-header & {
    @include media-query-min($tablet) {
      @include fs(16);
    }
  }

  .project-header & {
    border: calc($border-width/3) solid var(--color-primary);
    padding: calc($spacing-unit/2);
  }
}

.project-title {
  &__teaser {
    line-height: 1.2;
  }

  .project-header & {
    line-height: 1;
    margin-bottom: 0;
  }
}

.project-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: $spacing-unit;
}

.project-categories {
  display: flex;
  flex-flow: row wrap;
  gap: calc($spacing-unit/2);

  .category-name {
    padding: calc($spacing-unit / 4) calc($spacing-unit / 2);
  }
}

.project__dates {
  @include fs(24);

  font-weight: $strong-font-weight;
  border-bottom: $border-width solid var(--color-secondary);
  padding-bottom: calc($spacing-unit /2);
}


/**
 * Full Posts
 */
article.post {
  @include media-query-min($tablet) {
    padding-top: calc($spacing-unit * 2);
  }

  @include media-query-min($laptop) {
    padding-top: calc($spacing-unit * 4);
  }
}

.post-featured-image.full {
  margin: 0 auto;
  max-width: $content-width;
  position: relative;
  color: var(--link-color);
  padding: 0;
  background-color: var(--background-color-layer2);

  picture.full-feature {
    position: relative;
  }
  
  @include media-query-min($tablet) {
    max-width: calc(90vw + 2em);

    picture {
      box-shadow: 0 0 100px var(--color-secondary);
    }
  }

  @include media-query-min($desktop) {
    max-width: calc(900px + 4em);
  }

  @include media-query-min($wide-desktop) {
    max-width: calc(#{$content-width-large} + (#{$spacing-unit*6}));
  }

  .post-header,
  .project-header {
    position: relative;
    padding: $spacing-unit calc($spacing-unit * 2);
    background-color: var(--color-primary);
    
    @include media-query-min($laptop) {
      position: absolute;
      left: calc($spacing-unit * 2);
      bottom: -$spacing-unit;
    }
    
    h1,
    .category-name {
      position: relative;
      color: white;
      z-index: 2;
    }

    @include media-query-min($laptop) {
      background-color: transparent;
      max-width: 770px;

      &::before {
        position: absolute;
        content: "";
        bottom: 0;
        left: 0;
        height: 100%;
        width: 100%;
        padding: $spacing-unit;
        background-color: var(--color-primary);
        mix-blend-mode: multiply;
        z-index: 1;
      }
      &::after {
        position: absolute;
        content: "";
        bottom: $spacing-unit;
        left: $spacing-unit;
        mix-blend-mode: multiply;
        height: 100%;
        width: 100%;
        padding: $spacing-unit;
        background-color: var(--color-secondary);
        z-index: 1;
        display: block;
      }
    }
  }

  .travel-dates,
  .project__dates {
    margin: 0;
    color: white;
    position: relative;
    z-index: 2;
    border-color: var(--color-secondary);
  }
}

.post-featured-image.full.alternate {
  @include media-query-min($tablet) {
    picture {
      box-shadow: 0 0 100px var(--color-tertiary);
    }
  }
}

.post-content {
  margin-bottom: $margin-unit;
}

.post-footer {
  border-top: 1px solid var(--background-color-layer2);
  padding-top: $spacing-unit;
  margin-top: calc($margin-unit * 2);
  padding-bottom: $spacing-unit;
}

// flags
.flag {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  max-width: 60px;

  picture {
    width: 100%;
    max-width: 150px;
    margin: 0;
  }

  @include media-query-min($tablet) {
    max-width: 100px;
  }
  
  img {
    width: 100%;
    max-width: 150px;
  }
}



/**
 * Categories Page content
 */
.taxonomy-wrap {
  margin: 0 auto;

  .item + .item {
    margin-top: calc($margin-unit * 2);
  }
}

.category-block {
  padding-bottom: calc($spacing-unit * 4);
  margin-bottom: calc($margin-unit * 4);
  border-bottom: $border-width solid var(--color-secondary);
}

.tag-block {
  padding-top: $spacing-unit;
}

// pagination
.pagination {
  @include fs(18);

  display: flex;
  flex-flow: row wrap;
  margin-top: calc($margin-unit * 3);
  align-items: center;
  justify-content: space-between;
  color: var(--text-color);
  background-color: var(--background-color-layer2);
  border: 1px solid var(--background-color-layer3);
  gap: 1rem;

  @include media-query-max($laptop) {
    text-align: center;
    margin-bottom: calc($margin-unit * 3);
  }

  a {
    @include button($no-border-radius: true);
  
    @include media-query-max($laptop) {
      flex: 1 1 100%;
    }
  }

  span,
  a {
    padding: calc($spacing-unit / 2) $spacing-unit;
  }

  span.previous,
  span.next,
  .page_number {
    @include media-query-max($laptop) {
      flex: 0 100%;
    }
  }
}
