/**
* Fonts
*/
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Montserrat Regular'), local('Montserrat-Regular'),
       url('../fonts/montserrat-v14-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    
}
/**
 * Reset some basic elements
 */
body, h1, h2, h3, h4, h5, h6,
p, blockquote, pre, hr,
dl, dd, ol, ul, figure {
  margin: 0;
  padding: 0;
}

/**
 * Basic styling
 */
body {
  font: $base-font-weight #{$base-font-size}/#{$base-line-height} $base-font-family;
  color: var(--text-color);
  background: linear-gradient(180deg, var(--background-color) 0%, var(--background-color-layer2) 100%); 
  -webkit-text-size-adjust: 100%;
  -webkit-font-feature-settings: "kern" 1;
     -moz-font-feature-settings: "kern" 1;
       -o-font-feature-settings: "kern" 1;
          font-feature-settings: "kern" 1;
  font-kerning: normal;
  display: flex;
  height: 100%;
  min-height: 100vh;
  flex-direction: column;
  position: relative;
}


/**
 * Set `margin-bottom` to maintain vertical rhythm
 */
h1, h2, h3, h4, h5, h6,
p, blockquote, pre,
ul, ol, dl, figure,
%vertical-rhythm {
  margin-bottom: calc($margin-unit * 2);
}

hr {
  margin-bottom: calc($margin-unit * 4);
  padding-top: $spacing-unit;  
  border-top-width: 0;
  border-left-width: 0;
  border-right-width: 0;
  border-bottom-width: calc($spacing-unit / 2);
  border-color: var(--color-secondary);
  border-style: solid;
}
/**
 * screen reader text
 */
.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute;
  width: 1px;
  /* many screen reader and browser combinations announce broken words as they would appear visually */
  word-wrap: normal;
}


/**
 * `main` element
 */
main,
div {
  display: block; /* Default value of `display` of `main` element is 'inline' in IE 11. */
}

article {
  position: relative;

  li + li {
    margin-top: $margin-unit;
  }

  p {
    @include media-query-min($tablet) {
      max-width: 65ch;
    }
  }
}

/**
 * Images
 */
img {
  max-width: 100%;
  vertical-align: middle;
}


/**
 * Figures
 */
figure > img {
  display: block;
}

figcaption {
  @include fs(18);
  padding: calc($spacing-unit / 2) $spacing-unit;
  font-weight: $strong-font-weight;
  font-style: italic;
}

figure + figure,
noscript + picture {
  margin-top: calc($margin-unit * 4);
}

/**
 * Picture
 */

picture.post-default {
  display: block;
  background-color: var(--color-neutral-gray-pink);

  .wrapper__post & {
    margin-left: - calc(($margin-unit * 2) * -1);
    margin-right: - calc(($margin-unit * 2) * -1);

    @include media-query-min($tablet) {
      margin-left: calc(($margin-unit * 4) * -1);
      margin-right: calc(($margin-unit * 4) * -1);
    }

    @include media-query-min($wide-desktop) {
      margin-left: calc(($margin-unit * 6) * -1);
      margin-right: calc(($margin-unit * 6) * -1);
    }
  }
}

picture > img {
  width: 100%;
  height: auto;
  display: block;
  opacity: 0;
  transition: opacity $transition-timing;

  &.lazyloaded {
    opacity: 1;
    transition: all $transition-timing;
  }
}

.no-js {
  picture > img {
    opacity: 1;
  }

  picture {
    display: none;
  }

  img.lazyload {
    width: 100%;
  }
}

/////
// Gallery 
/////
picture.gallery-item {
  max-width: 300px;
}

.gallery-thumb {
  width: 100%;
  object-fit: cover;
  aspect-ratio: 357 / 238;
}

.post-gallery {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  margin-bottom: $margin-unit;

  @include media-query-min($tablet) {
    grid-template-columns: 1fr 1fr;
  }

  @include media-query-min($desktop) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

// hide broken images until src is loaded
img.lazyload:not([src]) {
	visibility: hidden;
}

/**
 * Lists
 */
ul, ol {
  margin-left: $margin-unit;
}

li {
  @include fs(22);

  > ul,
  > ol {
    margin-top: $margin-unit;
    margin-bottom: 0;
  }
}

.term-list {
  margin: 0;
  padding: 0;
  display: block;

  li {
    @include fs(18);
    list-style: none;
    margin-bottom: calc($margin-unit / 4);
  }
  a {
    padding:0 0 calc($spacing-unit / 4);
    line-height: 1.2;
    display: inline-block;
    font-weight: $strong-font-weight;
  }
}

p {
  @include fs(26);

  @include media-query-max($laptop) {
    @include fs(20);
  }

  &.button {
    @include fs(24);

    @include media-query-max($laptop) {
      @include fs(18);
    }
  }
}
/**
 * Headings
 */
 h1, h2, h3, h4, h5, h6 {
  font-family: $heading-font-family;

  &.post-title {
    line-height: 1.2;
    margin-top: $margin-unit;
    margin-bottom: calc($margin-unit / 2);
  }
}
h1.post-title {
  &.post-title {
    line-height: 1.2;
    margin-top: $margin-unit;
    margin-bottom: $margin-unit;
  }
}

h3, h4, h5, h6 {
  font-weight: $serif-fw-bold;
}

h1, h2 {
  font-weight: $strongest-font-weight;
}

h1 {
  @include fs(44);

  @include media-query-max($laptop) {
    @include fs(30);
  }
}

h2 {
  @include fs(38);

  @include media-query-max($laptop) {
    @include fs(24);
  }

  &.project-title {
    margin: 0;

    &__teaser {
      @include fs(18);
      padding: 0 0 $spacing-unit;

      @include media-query-min($tablet) {
        @include fs(20);
      }
    }
  }
}

h3 {
  @include fs(30);

  @include media-query-max($laptop) {
    @include fs(20);
  }

  &.post-title {
    margin: 0;

    &__teaser {
      @include fs(18);
      padding: 0 0 $spacing-unit;

      @include media-query-min($tablet) {
        @include fs(20);
      }
    }
  }

   &.term-name {
    @include fs(20);
    
    @include media-query-min($tablet) {
      @include fs(28);
    }
  }
}

h4 {
  @include fs(26);

  @include media-query-max($laptop) {
    @include fs(20);
  }
}

/**
 * Text
 */
 strong {
  font-weight: $strong-font-weight;
 }

.travel-dates {
  @include fs(22);
  line-height: 1;
  display: inline-block;
  font-weight: $sans-fw-bold;
  border-top: 0.25rem solid var(--color-secondary);
  padding: $spacing-unit 0 0;
  margin-bottom: calc($spacing-unit * 3);
  color: var(--color-secondary);
}

.welcome {
  @include fs(36);
  font-family: $heading-font-family;

  @include media-query-max($laptop) {
    @include fs(20);
  }
}


/**
 * Blockquotes
 */
blockquote {
  @include fs(26);
  color: var(--text-color);
  font-weight: $strongest-font-weight;
  padding: calc($spacing-unit * 2) calc($spacing-unit * 2);
  margin: calc($margin-unit * 2) 0;
  font-style: italic;
  position: relative;
  z-index: 0;
  outline: solid var(--color-primary) 0.1rem;
  outline-offset: -$spacing-unit;

  p {
    text-shadow: 1px 1px 1px var(--color-primary);

    @include media-query-max($wide-desktop) {
      max-width: 52ch;
    }
  }
  
  > :last-child {
    margin-bottom: 0;
  }
}

/**
 * Code formatting
 */
pre,
code {
  @include fs(18);
  border: $border-width solid var(--color-neutral-gray-pink);
  border-radius: 3px;
  font-weight: normal;
}

code {
  padding: 1px 5px;
}

pre {
  padding: 8px 12px;
  overflow-x: auto;

  > code {
    border: 0;
    padding-right: 0;
    padding-left: 0;
  }
}

.accented {
  position: relative;
  padding: 0 calc($spacing-unit / 2);
  margin: 0 0 calc($margin-unit * 2);
  border-left: $border-width solid var(--link-color);
}


/**
 * Wrapper
 */
.wrapper {
  margin-right: auto;
  margin-left: auto;
  position: relative;
  max-width:         calc(#{$content-width} - (#{$spacing-unit*2}));
  padding-right: calc($spacing-unit * 2);
  padding-left: calc($spacing-unit * 2);

  @include media-query-min($wide-desktop) {
    max-width:         calc(#{$content-width-large} - (#{$spacing-unit*4}));
    padding-right: calc($spacing-unit*2);
    padding-left: calc($spacing-unit*2);
  }

  &__home {
    padding-top: calc($spacing-unit * 2);
    padding-bottom: $spacing-unit;
    margin: 0 auto;

    .post-content {
      @include media-query-min($tablet) {
        padding: 0 calc($spacing-unit * 2) calc($spacing-unit * 2);
      }
    }
  }

  &__post {
    padding-top: calc($spacing-unit * 3);
    padding-bottom: calc($spacing-unit * 2);
    background-color: var(--background-color-layer2);
    border-top: 8px solid #DD403A;
    position: relative;

    &::before {
      content:'';
      position:absolute;
      width:100%;
      height: 8px;
      background:linear-gradient(to right, #DD403A, #907AD6);
      top: -8px;
      left:0;
    }

    @include media-query-min($tablet) {
      padding-top: calc($spacing-unit * 4);
      max-width: calc(90vw - (#{$spacing-unit*6}));
      padding-right: calc($spacing-unit * 4);
      padding-left: calc($spacing-unit * 4);
    }
    
    @include media-query-min($laptop) {
      margin-bottom: calc($margin-unit * 2);
    }

    @include media-query-min($desktop) {
      max-width: calc(900px - (#{$spacing-unit*4}));
    }

    @include media-query-min($wide-desktop) {
      padding-right: calc($spacing-unit * 6);
      padding-left: calc($spacing-unit * 6);
      max-width: calc(#{$content-width-large} - (#{$spacing-unit*6}));
    }

    // color by post
    &--jamaica {
      &::before {
        background:linear-gradient(to right, #f8fc18, #74f50b);
      }
    }
    &--india {
      &::before {
        background:linear-gradient(to right, #FE9834, #128905);
      }
    }
    &--taiwan {
      &::before {
        background: linear-gradient(to right, #3B5BA2, #C20129);
      }
    }
    &--germany {
      &::before {
        background: linear-gradient(to right, #DE0103, #FDCF00);
      }
    }
    &--italy {    
      &::before {
        background: linear-gradient(to right, #029246, #D02A35);
      }
    }
    &--mexico {
      &::before {
        background: linear-gradient(to right, #029245, #CB1223);
      }
    }
    &--france {
      &::before {
        background: linear-gradient(to right, #012392, #EB293B);
      }
    }
    &--uganda {
      &::before {
        background: linear-gradient(to right, #D90000, #FCDC04);
      }
    }
    &--rwanda {
      &::before {
        background: linear-gradient(to right, #00A3E0, #FAD201, #20603D);
      }
    }
  }

  &__page {
    padding-bottom: calc($spacing-unit * 2);
    background-color: var(--background-color-layer2);
    border-top: 8px solid #DD403A;
    
    @include media-query-min($tablet) {
      padding-top: calc($spacing-unit * 2);
    }

    &::before {
      content:'';
      position: absolute;
      width:100%;
      height: 8px;
      background:linear-gradient(to right, #DD403A, #907AD6);
      top: -8px;
      left:0;
    }
    
   @include media-query-min($tablet) {
      padding-top: calc($spacing-unit * 4);
      max-width: calc(90vw - (#{$spacing-unit*6}));
      padding-right: calc($spacing-unit * 4);
      padding-left: calc($spacing-unit * 4);
    }
    
    @include media-query-min($laptop) {
      margin-bottom: calc($margin-unit * 2);
    }

    @include media-query-min($desktop) {
      max-width: calc(900px - (#{$spacing-unit*4}));
    }

    @include media-query-min($wide-desktop) {
      padding-right: calc($spacing-unit * 6);
      padding-left: calc($spacing-unit * 6);
      max-width: calc(#{$content-width-large} - (#{$spacing-unit*6}));
    }
  }

  &__archive {
    padding-bottom: calc($spacing-unit * 2);
    padding-top: calc($spacing-unit * 2);

    @include media-query-min($laptop) {
      margin-bottom: calc($margin-unit * 4);
    }

    &.category-items {
      h1 {
        @include fs(30);
      }
      
      h2 {
        @include fs(26);
        margin-bottom: $margin-unit;
      }
      
      h3 {
        @include fs(20);
        margin-bottom: $margin-unit;
      }

      @include media-query-min($laptop) {
        margin-top: calc($margin-unit * 2);
        h1 {
          @include fs(34);
        }
        h2 {
          @include fs(28);
        }
        h3 {
          @include fs(26);
        }
      }
    }
  }
  &__footer {
    padding-top: $spacing-unit;
    padding-bottom: $spacing-unit;
  }
  &__articles {
    padding-bottom: 0;
  }
}

/**
 * Clearfix
 */
%clearfix:after {
  content: "";
  display: table;
  clear: both;
}

/**
 * Icons
 */

.svg-icon {
  width: 16px;
  height: 16px;
  display: inline-block;
  padding-right: 5px;
  vertical-align: text-top;
  fill: var(--color-neutral-gray-pink);
}

.svg-icon.bluesky path {
  width: 100%;
  max-width: 16px;
  max-height: 16px;
}
