
/**
 * Links
 */
 a {
  color: var(--link-color);
  text-decoration: none;
  transition: all $transition-timing ease-in-out;
  
  &:focus,
  &:hover {
    color: var(--link-hover);
  }
  &:focus {
    outline: dotted var(--link-color) calc($border-width / 4);
    outline-offset: calc($spacing-unit / 4);
  }
  &:visited {
    color: var(--link-visited);

    &:focus,
    &:hover {
      color: var(--link-hover);
    }
  }

  &.browse-by-link {
    margin-bottom: $margin-unit;
    display: inline-block;
    font-size: 1rem;

    &:hover {
      text-decoration: underline;
    }
  }
}

// buttons 

@mixin button($no-border-radius: false) {
  background-color: var(--link-color);
  color: var(--button-link-color);
  padding: calc($spacing-unit / 2) calc($spacing-unit * 2);
  border-bottom: $border-width solid var(--link-hover);
  font-weight: $strong-font-weight;
  transition: all $transition-timing ease-in-out;
  border-radius: if($no-border-radius, 0, calc($spacing-unit / 2));

  &:focus,
  &:hover {
    background-color: var(--link-hover);
    color: var(--button-link-color);
    border-color: var(--link-visited);
    transition: all $transition-timing ease-in-out;
  }
}

.button {
  margin-top: calc($margin-unit * 2);
  display: inline-block;
}
.button--wrap {
  width: 100%;
  margin-bottom: calc($margin-unit * 2);
}
.button--centered {
  text-align: center;
}
.button a {
 @include button;
}

.back-link {
  @include fs(20);

  display: inline-block;
  font-weight: $strong-font-weight;
  margin-bottom: calc($margin-unit * 2);

  &.top {
    margin-bottom: calc($margin-unit * 2);
  }

  &.bottom {
    margin-top: calc($margin-unit * 2);
  }
}

.website-link {
  @include fs(24);

  display: inline-block;
  font-weight: $strong-font-weight;
}


// header links
.site-title {
  display: block;
  flex: 0 auto;
  align-self: center;

  @include media-query-min(690px) {
    grid-row: span 2;
  }
  svg {
    width: 125px;
    height: auto;
    display: inline-block;
    fill: var(--text-color);

    @include media-query-min($laptop) {
      width: 200px;
    }
  }

  &:focus,
  &:hover {
    svg {
      fill: var(--link-hover);
    }
  }
}

// nav
.site-nav {
  margin: 0;
  padding: $spacing-unit 0;

  ul {
    margin: 0;
    padding: 0;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    gap: 1rem;

    @include media-query-min(800px) {
      justify-content: flex-end;
      gap: 1.5rem;
    }
  }

  li {
    flex: 0 auto;
    list-style-type: none;
    @include fs(16);
  
    @include media-query-min($tablet) {
      @include fs(18);
    }
  }
  
  .page-link {
    color: var(--text-color);
    line-height: $base-line-height;
    font-weight: $strong-font-weight;
    display: inline-block;
    position: relative;

    &:focus,
    &:hover {
      color: var(--link-hover);

      &::after {
        position: absolute;
        content: "";
        bottom: -$border-width*2;
        left: 0;
        height: $border-width;
        width: 100%;
        background-color: var(--link-hover);
      }
    }

    &.active {
      color: var(--link-color);

      &::after {
        position: absolute;
        content: "";
        bottom: -$border-width*2;
        left: 0;
        height: $border-width;
        width: 100%;
        background-color: var(--link-color);
      }
    }
  }
}

article.post  {
  .post-featured-image.full {
    aspect-ratio: 1296 / 432;

    .full-feature img {
      width: 100%;
      object-fit: cover;
    }
    .post-header,
    .project-header {
      .category-name a {
        padding: 0 calc($spacing-unit / 4);
        border: none;
        display: inline-block;
        margin: 0 0 calc($margin-unit / 2);
        
        &:hover {
          background-color: var(--link-hover);
          color: var(--link-visited);
        }
      }
    }
  }
  .post-header,
  .project-header {
    .category-name a {
      margin: 0 0 calc($margin-unit / 2);
    }
  }
  .post-footer {
    .category-name a {
      padding: 0 0.25rem;
      display: inline-block;
      border: calc($border-width / 3) solid var(--link-color);

      &:focus,
      &:hover {
        padding-bottom: 0;
        border: calc($border-width / 3) solid var(--link-hover);
      }
    
      &:visited {
        border: calc($border-width / 3) solid var(--link-visited);
        
        &:focus,
        &:hover {
          padding-bottom: 0;
          border: calc($border-width / 3) solid var(--link-color);
        }
      }
    }
  }
}

// link-wrapped post teasers
a.post-link {
  position: relative;
  display: block;
  transition: all $transition-timing ease-in-out;
  color: var(--link-color);
  border-bottom: $border-width solid var(--link-color);
  height: 100%;
  background-color: var(--background-color-layer2);

  .post-featured-image.teaser {
    background-color: var(--background-color-layer2);
    aspect-ratio: 357 / 119;

    img {
      width: 100%;
      object-fit: cover;
    }
  }
  
  .category-name {
    margin-top: $margin-unit;
    margin-bottom: $margin-unit;
    padding: 0 calc($spacing-unit / 4);
    border: calc($border-width / 4) solid var(--link-color);
  }

  .post-title__teaser {
    flex: 1 auto;
  }

  &:hover,
  &:focus {
    color: var(--link-hover);
    border-bottom: $border-width solid var(--link-hover);
    outline-color: var(--link-hover);

    .category-name {
      color: var(--link-hover);
      border-color: var(--link-hover);
    }

    .post-featured-image.teaser picture {
      opacity: 0.6;
      filter: saturate(0);
    }

    .post-featured-image.teaser {
      background-color: var(--background-color-hover);
    }
  }
  &:visited {
    color: var(--link-visited);
    border-bottom: $border-width solid var(--link-visited);
    
    .category-name {
      color: var(--link-visited);
      border-color: var(--link-visited);
    }

    .post-featured-image.teaser {
      background-color: var(--background-color-hover);
    }

    &:hover,
    &:focus {
      color: var(--link-hover);
      border-bottom: $border-width solid var(--link-hover);
      outline-color: var(--link-hover);
      
      .category-name {
        color: var(--link-hover);
        border-color: var(--link-hover);
      }

      .post-featured-image.teaser {
        background-color: var(--background-color-hover);
      }
    }
  }
}

// gallery image thumbnails
a.gallery-item__link {
  background-color: var(--link-color);

  &:hover,
  &:focus {
    background-color: var(--link-hover);

    .gallery-thumb {
      opacity: 0.6;
      filter: saturate(0);
    }
  }
}


// post page links
article.post a:not(.gallery-item__link) {
  color: var(--link-color);
  margin: 0 calc($margin-unit / 4);
  font-weight: $strongest-font-weight;
  border-bottom: calc($border-width / 3) solid var(--link-color);

  &:focus,
  &:hover {
    color: var(--link-hover);
    padding-bottom: calc($spacing-unit / 10);
    border-bottom: calc($border-width / 2) solid var(--link-hover);
  }

  &:visited {
    color: var(--link-visited);
    border-bottom: calc($border-width / 3) solid var(--link-visited);
    
    &:focus,
    &:hover {
      color: var(--link-color);
      padding-bottom: calc($spacing-unit / 10);
      border-bottom: calc($border-width / 2) solid var(--link-hover);
    }
  }
}

// footer links 
.social-list {
  flex:1 100%;
  margin-top: $margin-unit;
  display: flex;
  flex-flow: row wrap;
  align-items: flex-end;

  @include media-query-min($tablet) {
    flex: 1 60%;
    display: flex;
    flex-flow: column wrap;
    align-items: flex-end;
    margin-top: 0;
  }

  .social-media-list {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-end;

    li {
      flex: 1 auto;
    }
    a {
      @include fs(16);
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      padding: calc($spacing-unit / 4);
      margin: calc($margin-unit / 4);
      justify-content: center;

      svg {
        fill: var(--link-color);
      }
      
      &:focus,
      &:hover {
        text-decoration: none;
        svg {
          fill: var(--link-hover);
        }
        .username {
          text-decoration: underline;
        }
      }

      &:visited {
        svg {
          fill: var(--link-visited);
        }
        &:hover {
          svg {
            fill: var(--link-hover);
          }
        }
      }
    }
  }
}
